<template>
  <div>
    <!-- select 2 demo -->

    <b-modal
      id="modal-login"
      no-close-on-backdrop
      centered
      ok-only
      size="md"
      hide-footer="true"
      title="Add Promo"
    >
      <b-form>
        <b-form-group>
          <b-row class="mt-1">
            <b-col cols="4">
              <h5 style="margin-top:10px;">Promo</h5>
            </b-col>
            <b-col>
              <b-form-input
                v-model="myObj.code"
                placeholder="Enter promo code here"
                @focusout="checkPromo()"
                ref="title"
              />
              <small class="text-danger" v-if="this.errors.code == true"
                >Please enter promo code here.
              </small>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group>
          <b-row class="mt-1">
            <b-col cols="4">
              <h5 style="margin-top:10px;">Validity</h5>
            </b-col>
            <b-col>
              <flat-pickr
                id="tradeLicenseExpiryDate"
                ref="validityDate"
                v-model="myObj.validity"
                class="form-control"
                placeholder="Select promo validity date."
                @on-change="CheckDate()"
              />
              <small class="text-danger" v-if="this.errors.validity == true"
                >Please select valid date.
              </small>
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group>
          <b-row class="mt-1">
            <b-col cols="4">
              <h5 style="margin-top:10px;">Discount</h5>
            </b-col>
            <b-col>
              <b-form-input
                v-model="myObj.discountPercentage"
                placeholder="Enter discount percentage here"
                @focusout="checkDiscount()"
              />
              <small
                class="text-danger"
                v-if="this.errors.discountPercentage == true"
                >Please enter discount percentage.
              </small>
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group>
          <b-row class="mt-1">
            <b-col cols="4">
              <h5 style="margin-top:10px;">Amount Limit</h5>
            </b-col>
            <b-col>
              <b-form-input
                v-model="myObj.maximumLimit"
                placeholder="Enter amount limit here"
                @focusout="checkAmount()"
                ref="title"
              />
              <small class="text-danger" v-if="this.errors.maximumLimit == true"
                >Please enter amount limit.
              </small>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <br />
      <div style="">
        <b-button
          class="float-right ml-5"
          variant="primary"
          @click="AddFlavour()"
        >
          <span class="text-nowrap">Save</span>
        </b-button>
      </div>
    </b-modal>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Add Flavour -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-start">
              <div style="padding-right:15px">
                <b-button @click="OpenAddModal()" variant="primary">
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  <span class="text-nowrap">Add Promo Code</span>
                </b-button>
              </div>
              <!-- <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              /> -->
            </div>
          </b-col>
          <!-- Per Page -->
          <!-- <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col> -->
        </b-row>
      </div>

      <div>
        <b-table :items="subCatItems" :fields="fields" striped>
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(actions)="data">
            <template>
              <feather-icon
                @click="OpenEditModal(data.item)"
                icon="EditIcon"
                size="16"
                class="align-middle text-body"
                v-b-tooltip.hover.v-danger
                v-b-tooltip.placement.left
                title="Edit promo"
              />
              <feather-icon
                @click="DeleteFlavour(data.item.promocodeId)"
                icon="DeleteIcon"
                size="16"
                class="ml-2 align-middle text-body"
                v-b-tooltip.hover.v-danger
                v-b-tooltip.placement.right
                title="Delete promo"
              />
            </template>
            <!-- 
      <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item    @click="OpenEditModal(data.item)">
              <feather-icon icon="EditIcon" />
              
              <span class="align-middle ml-50"   >Edit</span>
            </b-dropdown-item>
              <b-dropdown-item    @click="DeleteFlavour(data.item.subCategoryId)">
              <feather-icon icon="XIcon" />
              
              <span class="align-middle ml-50"   >Delete</span>
            </b-dropdown-item>
              -->
            <!-- <b-dropdown-item @click="AcceptOrder(data.item.orderId)">
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50" >Accept order</span>
            </b-dropdown-item>

            <b-dropdown-item @click="DenyOrder(data.item.orderId)">
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">Decline order</span>
            </b-dropdown-item> 
          </b-dropdown>-->
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalOrders"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaAside,
  BSpinner,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter';
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from 'vue-flatpickr-component';
import { BFormSelect } from 'bootstrap-vue';
//import Multiselect from "vue-multiselect";
import VueGallery from 'vue-gallery';
import moment from 'moment';
import { forEach } from 'postcss-rtl/lib/affected-props';

export default {
  components: {
    //Multiselect,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    //BMedia,
    //BMediaAside,
    //BSpinner,
    //BImg ,
    //BMedia,
    //BAvatar,
    //BLink,
    //BBadge,
    //BDropdown,
    //BDropdownItem,
    BPagination,
    //vSelect,
    flatPickr,
    //'gallery': VueGallery
  },
  props: {
    /* modalFilter: {
    type: [String, null],
    default: null,
    },
    modalFilterOptions: {
    type: Array,
    required: true,
    }*/
  },
  created() {
    this.LoadData();
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      currentDate: moment().format('YYYY-MM-DD'),

      plus:
        'https://appick.io/u/lessons/64a03015-aaab-44a2-85c7-fc83ad9417b7.png',
      fileProfile: '',
      logoloading: false,
      errors: {
        validity: false,
        code: false,
        discountPercentage: false,
        maximumLimit: false,
        validity: false,
      },
      statusOptions: ['Active', 'Inactive'],
      filterData: '',
      date: {
        range: '',
      },
      index: null,
      cover: '',
      docs: [],
      partners: [],
      fields: [
        '#',
        { label: 'Code', key: 'code' },
        { label: 'valid till', key: 'validity' },
        { label: 'discount', key: 'discountPercentage' },
        { label: 'Limit', key: 'maximumLimit' },
        'actions',
      ],
      items: [],
      subCatItems: [],
      selected: null,
      filterOptions: [
        { text: 'Most sold product', value: 'mostSold' },
        { text: 'Least sold product', value: 'leastSold' },
        { text: 'Most popular product', value: 'mostPopular' },
        { text: 'Least popular product', value: 'leastPopular' },
        { text: 'Most profitable product', value: 'mostProfitable' },
      ],
      rangeDate: null,
      myObj: {
        promocodeId: 0,
        validity: null,
        discountPercentage: '',
        maximumLimit: '',
        code: '',
        isUser: null,
        isUsed: null,
      },
      detailObj: {},
    };
  },
  methods: {
    checkTitle() {
      var elem = this.$refs['title'];
      return (elem.state = this.myObj.name.length > 2 ? true : false);
    },
    checkPromo() {
      if (this.myObj.code == '' || this.myObj.code.length < 2) {
        return (this.errors.code = true);
      } else {
        return (this.errors.code = false);
      }
    },
    checkAmount() {
      console.log('Hello', this.myObj.maximumLimit);
      var myre = /^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/;
      //re.test(this.value)
      //var x = parseFloat(this.myObj.maximumLimit);
      if (
        myre.test(this.myObj.maximumLimit) &&
        this.myObj.maximumLimit != 0 &&
        this.myObj.maximumLimit !== null
      ) {
        console.log('1');
        return (this.errors.maximumLimit = false);
        // value is out of range
      } else {
        console.log('2');

        return (this.errors.maximumLimit = true);
      }
    },
    checkDiscount() {
      var x = parseFloat(this.myObj.discountPercentage);
      if (isNaN(x) || x <= 0 || x > 100) {
        return (this.errors.discountPercentage = true);
        // value is out of range
      } else {
        return (this.errors.discountPercentage = false);
      }
    },
    CheckDate() {
      console.log(this.currentDate);
      if (
        this.myObj.validity <= this.currentDate ||
        this.myObj.validity == null
      ) {
        return (this.errors.validity = true);
      } else {
        // elem.state = true;
        return (this.errors.validity = false);
      }
    },
    OpenAddModal() {
      this.$bvModal.show('modal-login');
      this.myObj.promocodeId = 0;
      this.myObj.validity = null;
      this.myObj.discountPercentage = '';
      this.myObj.maximumLimit = null;
      this.myObj.code = '';
      this.myObj.isUser = null;
      this.myObj.isUsed = null;
    },
    OpenEditModal(row) {
      this.$bvModal.show('modal-login');
      this.myObj.promocodeId = row.promocodeId;
      this.myObj.validity = row.validity;
      this.myObj.discountPercentage = row.discountPercentage;
      this.myObj.maximumLimit = row.maximumLimit;
      this.myObj.code = row.code;
      this.myObj.isUser = row.isUser;
      this.myObj.isUsed = row.isUsed;
    },
    DeleteFlavour(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          var axios = require('axios');
          var config = {
            method: 'delete',
            url:
              'https://okaaik.fastech.pk/api/promoCodes/DeletepromoCode/' + id,
            headers: {
              Authorization:
                'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJBZG1pbiIsImVtYWlsIjoiYWRtaW5AYnJhbmRhd2F5LmNvbSIsImp0aSI6IjVhYTNmZjY1LTg3MzMtNGZiZi05OWRhLWYxNTZlZmMyMjY0NSIsImV4cCI6MTY3MjA0NzgzNCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.5e8OU5ISM0HmK1urL9Tv3kWZ96nnxcXRfUMC7vBFZP8',
            },
          };
          console.log(config.url);
          axios(config)
            .then(response => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === 'success') {
                Swal.fire(
                  'Success!',
                  'Promo code has been deleted.',
                  'success',
                ).then(res => {
                  this.LoadData();
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    selectlogo() {
      this.fileProfile = this.$refs.fileProfile.files[0];
      var axios = require('axios');
      //console.log(this.fileProfile.name);

      //console.log(this.fileProfile, this.loading );
      //Upload cover
      if (this.fileProfile !== '') {
        this.logoloading = true;
        let formData = new FormData();
        formData.append('file', this.fileProfile);
        console.log(this.fileProfile);
        axios
          .post('https://upload.appick.io', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          .then(e => {
            console.log(e.data);
            // this.file = undefined;
            var fn1 =
              'https://appick.io/u/lessons/' + e.data.myresp[0].filename;
            console.log(fn1);
            // console.log(fn);
            this.plus = fn1;
            this.myObj.image = this.plus;
            //
            console.log(this.plus);
            //console.log(this.myObj.coverImg);
            this.logoloading = 'loaded';

            console.log(this.loading);
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    deleteLogo() {
      //console.log(0);
      this.myObj.image = '';
      this.logoloading = false;
    },
    AddFlavour() {
      this.CheckDate();
      this.checkAmount();
      this.checkPromo();
      this.checkDiscount();
      if (
        this.CheckDate() == true ||
        this.checkAmount() == true ||
        this.checkPromo() == true ||
        this.checkDiscount() == true
      ) {
        return this.$bvToast.toast('Please fill the form correctly.', {
          title: 'Error!',
          variant: 'danger',
          toaster: 'b-toaster-bottom-center',
        });
      } else {
        console.log(this.myObj);
        if (this.myObj.promocodeId == 0) {
          console.log('Osama');
          var axios = require('axios');

          var config = {
            method: 'post',
            url: 'https://okaaik.fastech.pk/api/promoCodes/PostpromoCode',
            headers: {
              Authorization:
                'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJBZG1pbiIsImVtYWlsIjoiYWRtaW5AYnJhbmRhd2F5LmNvbSIsImp0aSI6IjVhYTNmZjY1LTg3MzMtNGZiZi05OWRhLWYxNTZlZmMyMjY0NSIsImV4cCI6MTY3MjA0NzgzNCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.5e8OU5ISM0HmK1urL9Tv3kWZ96nnxcXRfUMC7vBFZP8',
            },
            data: this.myObj,
          };

          axios(config)
            .then(response => {
              if (response.data.status === 'success') {
                console.log(response.data);
                this.$bvModal.hide('modal-login');
                this.LoadData();

                this.$bvToast.toast('Promo code added.', {
                  title: 'Success!',
                  variant: 'success',
                  toaster: 'b-toaster-bottom-center',
                });
              }

              //  this.data  =  response.data;
              //console.log(this.$store.state.userData.userID);
              //this.items = response.data;

              // console.log(this.items);
            })
            .catch(function(error) {
              console.log(error);
            });
        } else {
          //Edit
          var axios = require('axios');
          // this.$store.state.userData.userID
          var config = {
            method: 'put',
            url:
              'https://okaaik.fastech.pk/api/promoCodes/PutpromoCode/' +
              this.myObj.promocodeId,
            headers: {
              Authorization:
                'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJBZG1pbiIsImVtYWlsIjoiYWRtaW5AYnJhbmRhd2F5LmNvbSIsImp0aSI6IjVhYTNmZjY1LTg3MzMtNGZiZi05OWRhLWYxNTZlZmMyMjY0NSIsImV4cCI6MTY3MjA0NzgzNCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.5e8OU5ISM0HmK1urL9Tv3kWZ96nnxcXRfUMC7vBFZP8',
            },
            data: this.myObj,
          };

          axios(config)
            .then(response => {
              if (response.data.status === 'success') {
                this.$bvModal.hide('modal-login');
                console.log(response.data);
                this.LoadData();

                this.$bvToast.toast('Promo code updated.', {
                  title: 'Success!',
                  variant: 'success',
                  toaster: 'b-toaster-bottom-center',
                });
              } else {
                this.$bvToast.toast('Something went wrong.', {
                  title: 'Error!',
                  variant: 'danger',
                  toaster: 'b-toaster-bottom-center',
                });
              }

              //  this.data  =  response.data;
              //console.log(this.$store.state.userData.userID);
              //this.items = response.data;

              // console.log(this.items);
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      }
    },

    LoadData() {
      var axios = require('axios');
      // this.$store.state.userData.userID
      var config = {
        method: 'get',
        url: 'http://localhost:52736/api/promoCodes/GetpromoCodesforAdmin',
        headers: {
          Authorization:
            'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJBZG1pbiIsImVtYWlsIjoiYWRtaW5AYnJhbmRhd2F5LmNvbSIsImp0aSI6IjVhYTNmZjY1LTg3MzMtNGZiZi05OWRhLWYxNTZlZmMyMjY0NSIsImV4cCI6MTY3MjA0NzgzNCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.5e8OU5ISM0HmK1urL9Tv3kWZ96nnxcXRfUMC7vBFZP8',
        },
      };

      axios(config)
        .then(response => {
          // console.log(JSON.stringify(response.data));

          //  this.data  =  response.data;
          //console.log(this.$store.state.userData.userID);
          //this.items = response.data.query;
          this.subCatItems = [];
          response.data.forEach(elem => {
            elem.validity = elem.validity.split('T')[0];
            this.subCatItems.push(elem);
          });

          console.log(this.items);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
</style>
